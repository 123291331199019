import React, { useEffect, useMemo, useRef, useState } from "react";
import "./Songbook.scss";
import LeftSidebar from "../../components/layout/LeftSidebar/LeftSidebar";
import RightSidebar from "../../components/layout/RightSidebar/RightSidebar";
import SongEditor from "../../components/widgets/SongEditor/SongEditor";
import { Song, Songbook } from "../../@core/models";
import RestClient from "../../@core/restClient";
import { useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
  IoDesktopOutline,
  IoDownload,
  IoPencilOutline,
  IoPrint,
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoDocumentsOutline,
  IoMusicalNotesSharp
} from "react-icons/io5";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SongFullScreen from "../../components/widgets/UI/SongFullScreen";
import { forEach } from "lodash";

type EditModeState = [
  editMode: boolean,
  setEditMode: (editMode: boolean) => void
];
type CreateModeState = [
  editMode: boolean,
  setEditMode: (editMode: boolean) => void
];

type SongbookState = [
  songbook: Songbook | null,
  setSongbook: (songbook: Songbook) => void
];

interface ToggleSidebar {
  left: boolean;
  right: boolean;
}

type ToggleSidebarState = [
  openSidebar: ToggleSidebar,
  setOpenSidebar: (openSidebar: ToggleSidebar) => void
];

const DEFAULT_SONG = {
  title: "",
  songbookId: null,
  key: "",
  time: "",
  tempo: "",
  text: "",
  categories: []
};

const BRAKEPOINT = 992;

const musicKey = [
  "Ab",
  "Abm",
  "A",
  "Am",
  "Bb",
  "Bbm",
  "B",
  "Bm",
  "C",
  "Cm",
  "C#",
  "C#m",
  "D",
  "Dm",
  "Eb",
  "Ebm",
  "E",
  "Em",
  "F",
  "Fm",
  "F#",
  "F#m",
  "G",
  "Gm"
];

const SongbookPage = () => {
  const { songbookId, songId } = useParams();
  const [editMode, setEditMode]: EditModeState = useState(false);
  const [createMode, setCreateMode]: CreateModeState = useState(false);
  const [song, setSong] = useState<any>(DEFAULT_SONG);
  const [songbook, setSongbook]: SongbookState = useState<Songbook | null>(
    null
  );
  const [openSidebar, setOpenSidebar]: ToggleSidebarState =
    useState<ToggleSidebar>({
      left: window.innerWidth >= BRAKEPOINT,
      right: window.innerWidth >= BRAKEPOINT
    });
  const [screenMode, setScreenMode] = useState(false);
  const [forScreen, setForScreen] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [songCategories, setSongCategories] = useState<any[]>([]);
  const [songListModified, setSongListModified] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState<any>(
    JSON.parse(localStorage.getItem("User") || "{}")?.id
  );
  const [permission, setPermission] = useState(false);
  const [keyList, setKeyList] = useState(false);
  const [noChords, setNoChords] = useState(false);

  const text = useRef() as React.MutableRefObject<HTMLDivElement>;

  //change chord color

  const toggleChords = () => {
    if (noChords) {
      setNoChords(false);
    } else {
      setNoChords(true);
    }
  };

  const check = /A|B|C|D|E|F|G/;

  useEffect(() => {
    Array.from(text.current?.children || []).forEach((item) => {
      console.log("noChords", noChords);
      const checked = item.innerHTML.match(check);
      if (noChords && checked) {
        item.classList.add("hide");
        return;
      } else {
        item.classList.remove("hide");
      }
      if (checked && !noChords) {
        item.classList.add("chords");
      } else {
        item.classList.remove("chords");
      }
    });
  }, [noChords, song]);

  useEffect(() => {
    const colls = songbook?.collaborators.map((item: any) => item.id);

    if (
      userId === Number(songbook?.ownerId) ||
      colls?.includes(Number(userId))
    ) {
      setPermission(true);
    } else {
      setPermission(false);
    }
  }, [songbook]);

  //Categories
  useEffect(() => {
    (async () => {
      try {
        const response = await RestClient.getCategoryList();
        setCategoryList(response || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const addCategories = (event: any) => {
    const value = Number(event.target.value);
    const isChecked = event.target.checked;
    if (isChecked === true) {
      setSongCategories((oldValue) => [...oldValue, value]);
    } else {
      const filteredList = songCategories.filter((item) => item !== value);
      setSongCategories(filteredList);
    }
  };

  const closeFullScreen = () => {
    setScreenMode(false);
  };

  const toggleSidebar = (sidebar: "left" | "right") => {
    if (sidebar === "left") {
      setOpenSidebar({ ...openSidebar, left: !openSidebar.left });
    } else if (sidebar === "right") {
      setOpenSidebar({ ...openSidebar, right: !openSidebar.right });
    }
  };

  const create = () => {
    setCreateMode(true);
    setSong(DEFAULT_SONG);
    setSongCategories([]);
    if (window.innerWidth <= 992) toggleSidebar("right");
  };

  const closeSidebar = () => {
    if (window.innerWidth <= BRAKEPOINT) toggleSidebar("left");
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(`(min-width: ${BRAKEPOINT}px)`);
    const matchMediaEventHandler = (event: MediaQueryListEvent) => {
      setOpenSidebar({ left: event.matches, right: event.matches });
    };
    matchMedia.addEventListener("change", matchMediaEventHandler);
    return () => {
      matchMedia.removeEventListener("change", matchMediaEventHandler);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!songbookId) {
          return;
        }
        const book: any = await RestClient.getSongbook(songbookId);
        setSongbook(book || {});
        // if (book.songs.length === 0) console.log("response", book);
        // setSong(DEFAULT_SONG);
        //setEditMode(false);
        // console.log("from response", book.ownerId, book.collaborators);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [songbookId]);

  useEffect(() => {
    (async () => {
      await getSong();
    })();
  }, [songId]);

  const getSong = async () => {
    setCreateMode(false);
    try {
      if (!songId) {
        setSong(DEFAULT_SONG);
        return;
      }
      const data = await RestClient.getSong(songId);
      const song = {
        id: data.id,
        title: data.title,
        songbookId: data.songbookId,
        key: data.key,
        time: data.time,
        tempo: data.tempo,
        text: data.text,
        categories: data.categories
      };
      setSong(song || {});
      setEditMode(false);
      setForScreen([song]);
      setSongCategories(data.categories?.map((category) => category.id) || []);
      setNoChords(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveSong = async (songId: any) => {
    if (createMode) {
      try {
        const addedSong = await RestClient.addSong({
          ...song,
          songbookId: songbook?.id,
          categories: songCategories
        });
        setCreateMode(false);
        setSongListModified(true);
        setTimeout(() => {
          setSongListModified(false);
        }, 3000);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        delete song.id;
        const updatedSong = await RestClient.updateSong(songId, {
          ...song,
          categories: songCategories
        });
        getSong();
        setEditMode(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const openDeleteSong = () => {
    if (window.innerWidth <= 992) {
      toggleSidebar("right");
    }
    setDeleteModal(true);
  };

  const deleteSong = () => {
    try {
      (async () => {
        const deletedSong = await RestClient.deleteSong(song.id);
        if (deletedSong) {
          setDeleteModal(false);
          setSongListModified(true);
          setTimeout(() => setSongListModified(false), 300);
        }
      })();
    } catch (error) {
      console.log("error");
    }
  };

  const toolsIcons = useMemo(
    () => [
      {
        icon: <IoDesktopOutline />,
        text: "На весь екран",
        action: () => {
          setScreenMode(true);
        }
      },
      // { icon: <IoPrint />, text: "Роздрукувати", action: () => { return false; } },
      // { icon: <IoDownload />, text: "Завантажити", action: () => { return false; } },
      {
        icon: <IoPencilOutline />,
        text: "Редагувати",
        action: () => setEditMode(true)
      }
    ],
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTooltip = (props: any, text: string) => (
    <Tooltip {...props} className="tools-tooltip">
      {text}
    </Tooltip>
  );

  useEffect(() => {
    const content = document.getElementById("songBody");
    if (screenMode && content) {
      content.style.position = "fixed";
    }
    if (!screenMode && content) {
      content.style.position = "relative";
    }
  }, [screenMode]);

  const hideDeleteModal = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    if (deleteModal) {
      document.body.style.position = "fixed";
    }
    if (!deleteModal) {
      document.body.style.position = "relative";
    }
  }, [deleteModal]);

  useEffect(() => {
    if (editMode) {
      const existingCategories = document.querySelectorAll(".categories-item");
      existingCategories.forEach((item: any) => {
        if (songCategories.includes(Number(item.value))) {
          item.checked = true;
        }
      });
    } else {
      return;
    }
  }, [editMode]);

  const togleKeyList = (e: any) => {
    e.stopPropagation();
    setKeyList(!keyList);
  };

  const changeKey = (item: any) => {
    setKeyList(false);
    setSong({ ...song, key: item });
  };

  useEffect(() => {
    if (keyList) {
      document.body.addEventListener("click", () => {
        setKeyList(false);
      });
    } else {
      return;
    }
  }, [keyList]);

  return (
    <div className="songbook">
      {screenMode ? (
        <div>
          <SongFullScreen
            selectedSongs={forScreen}
            closeFullScreen={closeFullScreen}
          />
        </div>
      ) : null}
      <div className="title">
        <h2>{songbook?.title || "Не знайдено"}</h2>
      </div>
      <div className="content-row" id="songBody">
        <div
          className={`toggler left_${openSidebar.left ? "open" : "close"}`}
          onClick={() => toggleSidebar("left")}
        >
          {openSidebar.left ? (
            <IoArrowBackCircleOutline />
          ) : (
            <IoArrowForwardCircleOutline />
          )}
        </div>
        <div className={`left ${openSidebar.left ? "open" : "close"}`}>
          <LeftSidebar
            songListModified={songListModified}
            closeSidebar={closeSidebar}
          />
        </div>
        <div className="center">
          <>
            {!screenMode ? (
              <div className="tools">
                {/* {toolsIcons.map((item, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={(props) => renderTooltip(props, item.text)}
                  >
                    <button className="tool-icon" onClick={item.action}>
                      {item.icon}
                    </button>
                  </OverlayTrigger>
                ))} */}
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => renderTooltip(props, "Сховати акорди")}
                >
                  <button
                    className={noChords ? "chords-show-btn" : "chords-hide-btn"}
                    onClick={() => toggleChords()}
                  >
                    <IoMusicalNotesSharp />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={(props) => renderTooltip(props, "На весь екран")}
                >
                  <button
                    className="tool-icon"
                    onClick={() => {
                      setScreenMode(true);
                    }}
                  >
                    {<IoDesktopOutline />}
                  </button>
                </OverlayTrigger>
                {permission ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={(props) => renderTooltip(props, "Редагувати")}
                  >
                    <button
                      className="tool-icon"
                      onClick={() => setEditMode(true)}
                    >
                      {<IoPencilOutline />}
                    </button>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </div>
            ) : null}

            <div className="song">
              <div className="my-3">
                {editMode || createMode ? (
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Назва пісні"
                    value={song?.title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setSong({ ...song, title: event.target.value })
                    }
                  />
                ) : (
                  <h3>{song?.title}</h3>
                )}
              </div>
              <div className="data">
                <div className="key">
                  {songId || createMode || editMode ? (
                    <>
                      <p onClick={(e) => togleKeyList(e)}>
                        Тональність:{" "}
                        <input
                          type="text"
                          className="input-box"
                          defaultValue={song?.key}
                        />
                        {keyList ? (
                          <IoChevronUpOutline className="key-icon" />
                        ) : (
                          <IoChevronDownOutline className="key-icon" />
                        )}
                      </p>

                      {keyList ? (
                        <div className="key-select">
                          {musicKey.map((item, i) => (
                            <p
                              className="key-select-item"
                              key={i}
                              // onClick={
                              //   editMode || createMode
                              //     ? () => setSong({ ...song, key: item })
                              //     : changeKey
                              // }
                              onClick={() => changeKey(item)}
                            >
                              {item}
                            </p>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="time">
                  {editMode || createMode ? (
                    <p>
                      {" "}
                      Розмір:
                      <input
                        type="text"
                        className="input-box"
                        value={song?.time}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setSong({ ...song, time: event.target.value })}
                      />
                    </p>
                  ) : (
                    <p>
                      {" "}
                      {songId ? "Розмір:" : ""}
                      <span>{song?.time}</span>
                    </p>
                  )}
                </div>
                <div className="tempo">
                  {editMode || createMode ? (
                    <p>
                      Темп:
                      <input
                        type="text"
                        className="input-box"
                        value={song?.tempo}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setSong({ ...song, tempo: event.target.value })}
                      />
                    </p>
                  ) : (
                    <p>
                      {" "}
                      {songId ? "Темп:" : ""}
                      <span>{song?.tempo}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className="">
                {editMode || createMode ? (
                  <>
                    <p className="category-title">Категорії:</p>
                    <div className="form-control categories">
                      {categoryList.map((item: any) => (
                        <div className="category" key={item.id}>
                          <input
                            className="categories-item"
                            type="checkbox"
                            value={item.id}
                            name="categoryId"
                            id={item.id}
                            onClick={addCategories}
                          />
                          <label htmlFor={item.id}>{item.name}</label>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="show-categories">
                    <p>{songId ? "Категорії:" : ""}</p>

                    {song?.categories?.map((item: any) => (
                      <p className="show-categories-item" key={item.id}>
                        {item.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <div className="text">
                {editMode || createMode ? (
                  <SongEditor
                    row={song?.text || ""}
                    onRowChange={(row) => setSong({ ...song, text: row })}
                  />
                ) : (
                  <div
                    ref={text}
                    className="text-inner"
                    dangerouslySetInnerHTML={{
                      __html:
                        song && song.text
                          ? draftToHtml(JSON.parse(song.text))
                          : ""
                    }}
                  ></div>
                )}
              </div>
              {editMode || createMode ? (
                <div className="edit-action">
                  <button
                    className="btn-mcolor mx-3"
                    onClick={() => saveSong(songId)}
                  >
                    Зберегти
                  </button>
                  <button className="btn-plain mx-3" onClick={getSong}>
                    Скасувати
                  </button>
                </div>
              ) : null}
            </div>
          </>
        </div>
        <div className={`right ${openSidebar.right ? "open" : "close"}`}>
          <RightSidebar
            onCreate={create}
            openDeleteSong={openDeleteSong}
            permission={permission}
          />
        </div>
        <div
          className={`toggler right_${openSidebar.right ? "open" : "close"}`}
          onClick={() => toggleSidebar("right")}
        >
          {openSidebar.right ? (
            <IoArrowForwardCircleOutline />
          ) : (
            <IoArrowBackCircleOutline />
          )}
        </div>
      </div>
      {deleteModal ? (
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">
              Видалити пісню <span>{song.title}</span> ?
            </p>
            <div className="delete-box-btns">
              <button
                className="btn-mcolor delete-box-btn "
                onClick={deleteSong}
              >
                Так
              </button>
              <button
                className="btn-plain delete-box-btn "
                onClick={hideDeleteModal}
              >
                Ні
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SongbookPage;

// {songId ? (
// ) : (
//   <h3 className="no-song my-3">Пісня не обрана</h3>
// )}
