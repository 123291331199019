import React, { useEffect, useRef, useState } from "react";
import "./SBooks.scss";
import "./../../components/widgets/UI/SelectUsers.scss";
import { useNavigate, Link } from "react-router-dom";
import { IoEnter, IoChevronDown, IoChevronUp } from "react-icons/io5";
import RestClient from "../../@core/restClient";
import SelectUsers from "../../components/widgets/UI/SelectUsers";

const SBooks = () => {
  const [booksList, setBooksList]: any = useState([]);
  const [booksListCollaborator, setBooksListCollaborator]: any = useState([]);

  const [collaboratorsList, setCollaboratorsList] = useState<any[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [book, setBook] = useState<any>({
    id: 0,
    ownerId: 0,
    title: "",
    public: true,
    collaborators: []
  });
  const [yesDelete, setYesDelete] = useState(false);
  const emptyRef = useRef();
  const [userId, setUserId] = useState();
  const [permissionSee, setPermissionSee] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const [permissionEdit, setPermissionEdit] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const toggleSee = function (i: any) {
    const copy = [...permissionSee];
    if (copy[i] === false) {
      copy[i] = true;
    } else {
      copy[i] = false;
    }
    setPermissionSee(copy);
  };
  const toggleEdit = function (i: any) {
    const copy = [...permissionEdit];
    if (copy[i] === false) {
      copy[i] = true;
    } else {
      copy[i] = false;
    }
    setPermissionEdit(copy);
  };

  useEffect(() => {
    setUserId(JSON.parse(localStorage.getItem("User") || "{}")?.id);
  }, []);

  const titleHandler = (event: any) => {
    event.target.value !== ""
      ? setBtnSaveDisabled(false)
      : setBtnSaveDisabled(true);
    setBook({ ...book, title: event.target.value });
  };

  const radioHandler = (event: any) => {
    if (event.target.value === "public") setBook({ ...book, public: true });
    if (event.target.value === "private") setBook({ ...book, public: false });
  };

  const addCollaborators = function (collaborators: any) {
    console.log(collaborators);
    const participants = collaborators.map((i: any) => i.id);
    console.log(participants);
    setBook({
      ...book,
      collaborators: participants
    });
    console.log(book);
  };
  const saveBook = (book: any) => {
    console.log(book);

    if (!editMode) {
      try {
        (async () => {
          delete book.id;
          const result = await RestClient.addSongBook(book);
          console.log(result);
          setModal(false);
          //setIsNewGroupSent(true);
        })();
      } catch (error) {
        console.log(error);
      }
    } else {
      const bookToEdit = {
        title: book.title,
        public: book.public,
        collaborators: book.collaborators
      };
      try {
        (async () => {
          console.log(book);
          const result = await RestClient.editSongBook(book.id, bookToEdit);
          console.log(result);
          //setIsNewGroupSent(true);
          setEditMode(false);
          setModal(false);
        })();
      } catch (error) {
        console.log(error);
      }
    }
    if (emptyRef && emptyRef.current)
      (emptyRef.current as any).emptyCollaboratorsArray();
  };

  const hideCreateBox = () => {
    setModal(false);
    setEditMode(false);
  };

  const showCreateBox = () => {
    setModal(true);
  };

  const editBook = (book: any) => {
    setModal(true);
    setEditMode(true);
    setBtnSaveDisabled(false);
    setCollaboratorsList(book.collaborators);
    setBook(book);
  };

  const deleteBook = (id: any) => {
    try {
      (async () => {
        const bookDeleted = await RestClient.deleteSongBook(id);
        if (bookDeleted) {
          setYesDelete(false);
        }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const showDeleteModal = (book: any) => {
    setBook(book);
    setYesDelete(true);
  };

  const hideDeleteModal = () => {
    setYesDelete(false);
  };

  useEffect(
    function () {
      (async () => {
        try {
          const id = JSON.parse(localStorage.getItem("User") || "{}")?.id;
          if (!id) {
            console.log("incorrect User id");
            return;
          }
          const sbooks = await RestClient.getSongBooks(id);
          setBooksList(sbooks);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [modal, yesDelete, editMode]
  );

  useEffect(function () {
    (async () => {
      try {
        const id = JSON.parse(localStorage.getItem("User") || "{}")?.id;
        if (!id) {
          console.log("incorrect User id");
          return;
        }
        const sbooks = await RestClient.getSongBooksCollaborators(id);
        setBooksListCollaborator(sbooks);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (modal) {
      document.body.style.position = "fixed";
    }
    if (!modal) {
      document.body.style.position = "relative";
    }
  }, [modal]);

  useEffect(() => {
    if (yesDelete) {
      document.body.style.position = "fixed";
    }
    if (!yesDelete) {
      document.body.style.position = "relative";
    }
  }, [yesDelete]);

  return (
    <div className="sbooks" id="sbooks">
      <button className="btn-mcolor create-btn" onClick={showCreateBox}>
        Створити новий збірник
      </button>
      <div className="sbooks-all">
        <p className="label">Мої збірники</p>
        {booksList.map((book: any, i: any) => (
          <div key={book.id}>
            <div
              className={
                permissionEdit[i]
                  ? "sbooks-one sb-active addcolor"
                  : "sbooks-one sb-active"
              }
              onClick={() => {
                toggleEdit(i);
              }}
            >
              <div>
                {book.title}{" "}
                {permissionEdit[i] ? <IoChevronUp /> : <IoChevronDown />}
              </div>
              <div className="sbooks-one-icons">
                <Link to={`/songbook/${book.id}`}>
                  <div className="icon">
                    <IoEnter />
                  </div>
                </Link>
              </div>
            </div>
            {permissionEdit[i] ? (
              <div className="attributes" key={book.id}>
                <div>
                  <p className="attributes-status">
                    Статус:
                    <span>
                      {" "}
                      {book.public === true ? "загальний" : "приватний"}{" "}
                    </span>
                  </p>
                  <div className="attributes-users">
                    <p className="attributes-users-title">Учасники:</p>
                    {book.collaborators.map((user: any) => (
                      <p className="attributes-users-item" key={user.id}>
                        {user.firstName} {user.lastName}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="users-btn-box">
                  <button
                    className="create-box-btn btn-plain"
                    onClick={() => editBook(book)}
                  >
                    Редагувати
                  </button>
                  <button
                    className="create-box-btn btn-plain"
                    onClick={() => showDeleteModal(book)}
                  >
                    Видалити
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="sbooks-all">
        <p className="label">Приймаю участь:</p>
        {booksListCollaborator.map((book: any, i: any) => (
          <div key={book.id}>
            <div className="sbooks-one sb-active" onClick={() => toggleSee(i)}>
              <div>
                {book.title}{" "}
                {permissionSee[i] ? <IoChevronUp /> : <IoChevronDown />}
              </div>
              <div className="sbooks-one-icons">
                <Link to={`/songbook/${book.id}`}>
                  <div className="icon">
                    <IoEnter />
                  </div>
                </Link>
              </div>
            </div>
            {permissionSee[i] ? (
              <div className="attributes">
                <div>
                  <p className="attributes-status">
                    Статус:
                    <span>
                      {" "}
                      {book.public === true ? "загальний" : "приватний"}{" "}
                    </span>
                  </p>
                  {/* <div className="attributes-users">
                    <p className="attributes-users-title">Учасники:</p>
                    {book.collaborators.map((user: any) => (
                      <p className="attributes-users-item" key={user.id}>
                        {user.firstName} {user.lastName}
                      </p>
                    ))}
                  </div> */}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
      {modal ? (
        <div className="create-book">
          <div className="create-book-box">
            <p className="create-box-label">Введіть назву збірника</p>

            <input
              type="text"
              className="inputs"
              onChange={titleHandler}
              value={book.title}
            />
            <p className="create-box-label"> Виберіть статус</p>

            <div className="attributes-radio" onChange={radioHandler}>
              <input
                type="radio"
                id="public"
                name="status"
                value="public"
                defaultChecked={book.public === true}
              />
              <label htmlFor="public">Загальний</label>
              <input
                type="radio"
                id="private"
                name="status"
                className="itemcheck"
                value="private"
                defaultChecked={book.public === false}
              />
              <label htmlFor="private">Приватний</label>
              <div className="attributes-radio-item"></div>
              <div className="attributes-radio-item"></div>
            </div>

            <div>
              <p className="create-box-label">Додайте користувачів</p>
              <SelectUsers
                passingCollaboratorsArray={addCollaborators}
                ref={emptyRef}
                collaboratorsList={collaboratorsList}
                editMode={editMode}
              />
            </div>
            <div className="btn-box">
              <button
                className={`${
                  btnSaveDisabled
                    ? "create-box-btn btn-mcolor-inactive"
                    : "create-box-btn btn-mcolor"
                }`}
                disabled={btnSaveDisabled}
                onClick={() => saveBook(book)}
              >
                зберегти
              </button>
              <button
                className="create-box-btn btn-plain"
                onClick={hideCreateBox}
              >
                Скасувати
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {yesDelete ? (
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">
              Видалити збірник <span>{book.title}</span>? Всі пісні також будуть
              видалені.
            </p>
            <div className="delete-box-btns">
              <button
                className="btn-mcolor delete-box-btn "
                onClick={() => deleteBook(book.id)}
              >
                Так
              </button>
              <button
                className="btn-plain delete-box-btn "
                onClick={hideDeleteModal}
              >
                Ні
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SBooks;
