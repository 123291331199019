import React, { useCallback, useEffect, useState } from "react";
import "./LeftSidebar.scss";
import { IoSearchOutline } from "react-icons/io5";
import { Category, Filter, Song } from "../../../@core/models";
import RestClient from "../../../@core/restClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import debounce from "lodash/debounce";

type CategoriesState = [
  categories: Category[],
  setCategories: (categories: Category[]) => void
];

type SongsState = [songs: Song[], setSongs: (songs: Song[]) => void];

type FilterState = [
  filter: Filter,
  setFilter: (arg: Filter | ((current: Filter) => Filter)) => void
];

const LeftSidebar = ({ songListModified, closeSidebar }: any) => {
  const { songbookId, songId } = useParams();
  const [categories, setCategories]: CategoriesState = useState<Category[]>([]);
  const [songs, setSongs]: SongsState = useState<Song[]>([]);
  const [filter, setFilter]: FilterState = useState<Filter>({
    category: "",
    query: ""
  });
  const [sort, setSort] = useState("");
  const navigate = useNavigate();

  const deferredSetQueryHandler = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setFilter((current: Filter) => ({
        ...current,
        query: event.target.value
      }));
    }, 500),
    []
  );

  const getSongList = async () => {
    if (!songbookId) {
      return;
    }
    try {
      const response = await RestClient.getSongList(
        songbookId,
        filter.category,
        filter.query
      );
      console.log("getSongList response", response);
      setSongs(response.sort((a, b) => Number(b.id) - Number(a.id)) || []);
      if (response?.length) {
        navigate(
          // `/songbook/${songbookId}/song/${response[response.length - 1].id}`
          `/songbook/${songbookId}/song/${response[0].id}`
        );
      } else {
        navigate(`/songbook/${songbookId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sorting = (e: any) => {
    if (e.target.value === "az") {
      setSort(e.target.value);
    }

    console.log(songs);
    if (e.target.value === "za") {
      setSort(e.target.value);
    }
    if (e.target.value === "newOld" || e.target.value === "") {
      setSort(e.target.value);
    }
    if (e.target.value === "oldNew") {
      setSort(e.target.value);
    }
  };
  useEffect(() => {
    if (sort === "az") {
      const sorted = [...songs].sort((a, b) => a.title.localeCompare(b.title));
      setSongs(sorted);
    }

    console.log(songs);
    if (sort === "za") {
      const sorted = [...songs].sort((a, b) => b.title.localeCompare(a.title));
      setSongs(sorted);
    }
    if (sort === "newOld" || sort === "") {
      const sorted = [...songs].sort((a, b) => Number(a.id) - Number(b.id));
      setSongs(sorted);
    }
    if (sort === "oldNew") {
      const sorted = [...songs].sort((a, b) => Number(b.id) - Number(a.id));
      setSongs(sorted);
    }
  }, [sort]);

  useEffect(() => {
    (async () => {
      try {
        const response = await RestClient.getCategoryList();
        console.log("getCategoryList response", response);
        setCategories(response || []);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await getSongList();
    })();
  }, [filter, songListModified]);

  useEffect(() => {
    (async () => {
      if (filter.category || filter.query) {
        setFilter({ category: "", query: "" });
      } else {
        await getSongList();
      }
    })();
  }, [songbookId]);

  return (
    <div className="left-sidebar">
      <div className="search-field">
        <input
          type="text"
          className="search-song"
          placeholder="Пошук за назвою ..."
          onChange={deferredSetQueryHandler}
        />
        <IoSearchOutline className="search-icon" />
      </div>
      <div className="topic">
        <select
          className="topic-field"
          value={filter.category}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFilter({ ...filter, category: event.target.value });
          }}
        >
          <option value={""}>Всі Категорії</option>
          {categories?.map((category: Category) => (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="topic">
        <select
          name="sortSongs"
          id=""
          className="topic-field"
          onChange={sorting}
        >
          <option value="">Не сортувати</option>
          <option value="az">А - Я</option>
          <option value="za">Я - А</option>
          <option value="newOld">Нові - Старі</option>
          <option value="oldNew">Старі - Нові</option>
        </select>
      </div>
      <ul className="contents">
        {songs?.map((song: Song) => (
          <li
            key={song.id}
            className={Number(songId) === song.id ? "is-active" : ""}
            onClick={closeSidebar}
          >
            <Link to={`/songbook/${songbookId}/song/${song.id}`}>
              {song.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftSidebar;
