import React, { useState, useEffect, useRef } from "react";
import "./../../../../src/index.scss";
import "./SongFullScreen.scss";
import draftToHtml from "draftjs-to-html";
import SongEditor from "../SongEditor/SongEditor";
import { IoCloseOutline } from "react-icons/io5";
import { useDeprecatedAnimatedState } from "framer-motion";

const SongFullScreen = ({ selectedSongs, closeFullScreen }: any) => {
  const [layout, setLayout] = useState(false);
  const [displayButtons, setDisplayButtons] = useState(true);

  const carousel = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (selectedSongs.length !== 1) {
      setDisplayButtons(true);
    } else {
      setDisplayButtons(false);
    }
  }, []);

  const carouselIncrement = (delta: any) => {
    if (carousel.current) {
      const width = carousel.current.offsetWidth;
      carousel.current.scrollTo(carousel.current.scrollLeft + width * delta, 0);
    }
  };

  return (
    <div className="full-screen">
      <div ref={carousel} className="full-screen-inner">
        {selectedSongs.map((item: any) => (
          <div key={item.id} className="card">
            <div className="title-line">
              {displayButtons ? (
                <>
                  <div
                    className="carousel-btn btn-left"
                    onClick={() => carouselIncrement(-1)}
                  ></div>
                  <div
                    className="carousel-btn btn-right"
                    onClick={() => carouselIncrement(1)}
                  ></div>
                </>
              ) : (
                ""
              )}
              <div>
                <p className="title-line-title">{item.title}</p>
              </div>
              <div className="title-line-inner">
                <p className="title-line-key">{item.key}</p>
                {item.tempo ? (
                  <p className="title-line-tempo">
                    tempo {item.tempo}, {item.time}
                  </p>
                ) : (
                  ""
                )}
              </div>

              <IoCloseOutline
                onClick={closeFullScreen}
                className="close-icon"
              />
            </div>
            <div className="song-body">
              <div
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(JSON.parse(item.text))
                }}
                className={
                  !layout ? "song-body-text" : "song-body-text-horizontal"
                }
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SongFullScreen;
