import "./Features.scss";
import {
  IoDesktopOutline,
  IoMusicalNote,
  IoMusicalNotes,
  IoPeopleOutline,
  IoRefreshOutline,
  IoCloseCircleOutline,
  IoSearch,
  IoPersonOutline,
  IoSunnyOutline,
  IoCheckmarkSharp,
  IoCloseSharp
} from "react-icons/io5";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Songbook } from "../../../@core/models";
import debounce from "lodash/debounce";
import RestClient from "../../../@core/restClient";

const Features = () => {
  const queryRef = useRef<any>();
  const [query, setQuery] = useState<string>("");
  const [selectedSongbookId, setSelectedSongbookId] = useState<number | null>(
    null
  );
  const [songbooks, setSongbooks] = useState<Songbook[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState(window.innerWidth);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const navigate = useNavigate();

  const slide = useRef() as React.MutableRefObject<HTMLDivElement>;
  const slideOne = useRef() as React.MutableRefObject<HTMLDivElement>;

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const intersecting = entry.isIntersecting;
      if (intersecting) {
        setIsIntersecting(true);
        entry.target.classList.add("slide-out");
      }
      if (!intersecting) {
        entry.target.classList.remove("slide-out");
        setIsIntersecting(false);
      }
    });
  });

  useEffect(() => {
    observer.observe(slide.current);
    observer.observe(slideOne.current);
  }, [isIntersecting]);

  const deferredSetQueryHandler = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    }, 500),
    []
  );

  useEffect(
    function () {
      (async () => {
        if (
          queryRef.current !== document.activeElement ||
          queryRef.current.value !== query
        ) {
          return;
        }
        try {
          const response = await RestClient.getSongbookList(query);
          console.log("getSongbookList response", response);
          setSongbooks(response || []);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [query, isOpen]
  );

  const selectSongbook = (songbook: Songbook) => {
    queryRef.current.value = songbook.title;
    setQuery(songbook.title);
    setSelectedSongbookId(songbook.id);
    setSongbooks([]);
    navigate(`/songbook/${songbook.id}`);
    window.scrollTo(0, 0);
    setQuery("");
    setIsOpen(false);
  };
  // const submitSongbook = (e: any) => {
  //   e.stopPropagation();
  //   if (selectedSongbookId !== null) { // todo: check using incorrect id
  //     console.log(`navigate to /songbook/${selectedSongbookId}`);
  //     navigate(`/songbook/${selectedSongbookId}`);
  //     setQuery("");
  //     setIsOpen(false);
  //   } else {
  //     // todo: show notification
  //   }
  // };
  const openAgain = (e: any) => {
    e.stopPropagation();
    queryRef.current.value = "";
    //setQuery("");
    //setSelectedSongbookId(null);
    setIsOpen(true);
    console.log("state of open", isOpen);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("click", () => {
        setIsOpen(false);
      });
    } else {
      return;
    }
  }, [isOpen]);

  return (
    <div>
      <div>
        <div className="feature-features">
          <div
            ref={slideOne}
            className="feature-features-listbox feature-list-1"
          >
            <div className="feature-list ">
              <li>
                <IoMusicalNote className="feature-list-icon" /> Режим екрану
              </li>
              <li>
                <IoMusicalNote className="feature-list-icon" />
                Створення підбірок
              </li>
              <li>
                <IoMusicalNote className="feature-list-icon" />
                Вибір тональності
              </li>
            </div>
          </div>
          <div className="feature-imgbox">
            <img
              className="feature-imgbox-img"
              src="images/laptop.png"
              alt="a photo of a laptop"
            />
            <div className="feature-imgbox-motion-circle">
              <div className="music-note_1">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_2">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_3">
                <IoMusicalNotes className="note-style" />
              </div>
              <div className="music-note_4">
                <IoMusicalNotes className="note-style" />
              </div>
            </div>
          </div>
          <div ref={slide} className="feature-features-listbox feature-list-2">
            <div className="feature-list ">
              <li>
                <IoMusicalNote className="feature-list-icon" />
                Робота у команді
              </li>
              <li>
                <IoMusicalNote className="feature-list-icon" />
                Легкий пошук
              </li>
              <li>
                <IoMusicalNote className="feature-list-icon" />
                Миттєве оновлення
              </li>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center text-center">
          <div className="box-open">
            <p className="box-open-text-1"> Відкрий будь який збірник</p>
            <p className="box-open-text-2">без авторизації</p>
            <IoMusicalNote className="icon-1 note-style" />
            <IoMusicalNote className="icon-2 note-style" />
            <IoMusicalNote className="icon-3 note-style" />
            <IoMusicalNote className="icon-4 note-style" />
            <IoMusicalNote className="icon-5 note-style" />
            <div className="box-open-btn">
              <IoSearch className="search-icon" />
              <input
                className="box-open-btn-input"
                type="text"
                ref={queryRef}
                placeholder="Вибери збірник.."
                onChange={deferredSetQueryHandler}
                onClick={(e) => openAgain(e)}
              />
              {/* <button className="box-open-btn-button btn-mcolor" onClick={submitSongbook}>Відкрити</button> */}
              {songbooks?.length > 0 && isOpen && (
                <div className="select-container_two">
                  <IoCloseCircleOutline
                    className="close_two"
                    onClick={() => setIsOpen(false)}
                  />
                  {songbooks?.map((songbook: Songbook, index: number) => (
                    <div
                      className="select-item_two"
                      key={index}
                      onClick={() => selectSongbook(songbook)}
                    >
                      {songbook.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="title-for-options">Тарифні плани</div>

        <div className="options">
          <div className="options-box options-box-one">
            <IoSunnyOutline className="options-icon" />
            <p>
              Вільний <br /> <span>Безкоштовно</span>
            </p>
            <ul className="plan">
              <li className="plan-item">
                {" "}
                <IoCheckmarkSharp className="icon" />
                Один збірник
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                15 пісень
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Режим екрану
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Вибір тональності
              </li>
              <li>
                <IoCloseSharp className="icon" />
                Завантаження файлів
              </li>
              <li>
                <IoCloseSharp className="icon" />
                Створення підбірок
              </li>
              <li>
                <IoCloseSharp className="icon" />
                Запрошення учасників
              </li>
              <li>
                {" "}
                <IoCloseSharp className="icon" />
                Робота у команді
              </li>
            </ul>
          </div>
          <div className="options-box options-box-one">
            <IoPersonOutline className="options-icon" />
            <p>
              Індивідуальний <br />
              <span>...гр. / місяць</span>
            </p>
            <ul className="plan">
              <li className="plan-item">
                {" "}
                <IoCheckmarkSharp className="icon" />
                Два збірники
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Пісень необмежено
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Режим екрану
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Вибір тональності
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Завантаження файлів
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Створення підбірок
              </li>
              <li>
                <IoCloseSharp className="icon" />
                Запрошення учасників
              </li>
              <li>
                {" "}
                <IoCloseSharp className="icon" />
                Робота у команді
              </li>
            </ul>
          </div>
          <div className="options-box options-box-one">
            <IoPeopleOutline className="options-icon" />
            <p>
              Командний <br />
              <span>...гр. / місяць</span>
            </p>
            <ul className="plan">
              <li className="plan-item">
                {" "}
                <IoCheckmarkSharp className="icon" />
                Два збірники
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Пісень необмежено
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Режим екрану
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Вибір тональності
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Завантаження файлів
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Створення підбірок
              </li>
              <li className="plan-item">
                <IoCheckmarkSharp className="icon" />
                Запрошення учасників
              </li>
              <li className="plan-item">
                {" "}
                <IoCheckmarkSharp className="icon" />
                Робота у команді
              </li>
            </ul>
          </div>
          <div className="action-btn">
            <button className="register-button">Почни зараз!</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
