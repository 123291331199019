import React, { useState, useEffect, useRef } from "react";
import "./SLists.scss";
import ChooseSongs from "../../components/widgets/ChooseSongs/ChooseSongs";
import ShowSongs from "../../components/widgets/ChooseSongs/ShowSongs";
import {
  IoPencil,
  IoArrowUpCircleSharp,
  IoTrashOutline,
  IoChevronDownOutline,
  IoChevronUpOutline
} from "react-icons/io5";
import SelectSongBook from "../../components/widgets/UI/SelectSongBook";
import SelectUsers from "../../components/widgets/UI/SelectUsers";
import RestClient from "../../@core/restClient";
import SongFullScreen from "./../../components/widgets/UI/SongFullScreen";
import draftToHtml from "draftjs-to-html";

const SLists = () => {
  const [userId, setUserId] = useState("");
  const [creatorId, setCreatorId] = useState("");
  //const [newGoupId, setNewGroupId] = useState(null);
  const [selections, setSelections] = useState([]);
  const [selectionId, setSelectionId] = useState(null);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [sListSettings, setsListSettings] = useState("hide-modal");
  const [chooseOption, setChooseOption] = useState("chooseGroup");
  const [optionLabel, setOptionLabel] = useState("Вибрати групу");
  const [openList, setOpenList] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [chooseSongsModal, setChooseSongsModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [collaboratorsList, setCollaboratorsList] = useState<any[]>([]);
  const [showCollaborators, setShowCollaborators] = useState<any[]>([]);
  const [btnSaveDisabled, setBtnSaveDisabled] = useState(true);
  const [isNewGroupSent, setIsNewGroupSent] = useState(true);
  const [isSelectionSent, setIsSelectionSent] = useState(false);
  const [editedSelection, setEditedSelection] = useState({
    id: 0,
    name: "",
    date: "",
    songs: []
  });
  const [isCreate, setIsCreate] = useState(false);
  const [editedId, setEditedId] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [newGroupId, setNewGroupId] = useState(0);
  const [yesDelete, setYesDelete] = useState(false);

  const emptyRef = useRef();
  const refEdit = useRef();

  useEffect(() => {
    setUserId(JSON.parse(localStorage.getItem("User") || "{}")?.id);
  }, []);

  const newGroupNameHandler = function (event: any) {
    setNewGroupName(event.target.value);
    event.target.value !== ""
      ? setBtnSaveDisabled(false)
      : setBtnSaveDisabled(true);
  };

  // const optionHandler = (id: any, label: any, e: any) => {
  //   e.stopPropagation();
  //   setChooseOption(id);
  //   setOptionLable(label);
  //   setOpenList(false);
  //   chooseGroupHandler(id);
  // };

  const togleOpen = (e: any) => {
    e.stopPropagation();
    setOpenList(!openList);
  };

  const addCollaborators = function (collaborators: any) {
    setCollaboratorsList(collaborators);
  };

  const savingNewGroup = () => {
    if (!userId) return;
    const newGroup = {
      id: newGroupId,
      groupName: newGroupName,
      creatorId: userId,
      collaborators: collaboratorsList.map((i: any) => i.id)
    };
    if (editMode) {
      setNewGroupName("");
      setEditMode(false);
    } else {
      setNewGroupName("");
      setChooseOption("chooseGroup");
      setEditMode(false);
    }
    setBtnSaveDisabled(true);

    if (emptyRef && emptyRef.current)
      (emptyRef.current as any).emptyCollaboratorsArray();
    setsListSettings("hide-modal");
    saveNewGroupHandler(newGroup);
  };

  const saveNewGroupHandler = (enteredItem: any) => {
    if (!editMode) {
      try {
        (async () => {
          delete enteredItem.id;
          const result = await RestClient.addSelectionGroup(enteredItem);
          setIsNewGroupSent(true);
          getGroup(result.id);
          // setChooseOption("chooseGroup");
          // setOptionLabel("Вибрати групу");
        })();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        (async () => {
          const result = await RestClient.editSelectionGroup(enteredItem);
          setIsNewGroupSent(true);
          setEditMode(false);
          getGroup(result.id);
        })();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteGroup = (id: any) => {
    try {
      (async () => {
        const groupDeleted = await RestClient.deleteSelectionGroup(id);
        if (groupDeleted) {
          setChooseOption("chooseGroup");
          setOptionLabel("Вибрати групу");
          setsListSettings("hide-modal");
          setSelectionId(null);
          setBtnDisabled(true);
          setSelections([]);
          setIsNewGroupSent(true);
          setYesDelete(false);
        }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  //Dealing with song Selections

  const saveNewListHandler = (songSelection: any) => {
    if (!isCreate) {
      try {
        (async () => {
          const result = await RestClient.editSelection(songSelection);
          //getGroup(result.id);
        })();
        setIsSelectionSent(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        (async () => {
          const result = await RestClient.addSelection(songSelection);
        })();
        setIsSelectionSent(true);
        setIsCreate(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const deleteSelection = (id: number) => {
    try {
      (async () => {
        const result = await RestClient.deleteSelection(id);
        if (result) {
          setSelections(selections.filter((item: any) => item.id !== id));
        }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const editSelection = (id: number, name: string, date: any, songs: []) => {
    const edited = {
      id: id,
      name: name,
      date: date,
      songs: songs
    };
    console.log("selection to edit", edited);
    setEditedSelection(edited);

    setChooseSongsModal(true);
  };
  useEffect(
    function () {
      if (refEdit && refEdit.current) (refEdit.current as any).edit();
      setEditedId(editedSelection.id);
    },
    [editedSelection]
  );

  useEffect(() => {
    if (openList) {
      document.body.addEventListener("click", () => {
        setOpenList(false);
      });
    } else {
      return;
    }
  }, [openList]);

  const getGroup = (id: any) => {
    try {
      (async () => {
        const group = await RestClient.getSelectionGroup(id);
        console.log(group);
        if (group) {
          setChooseOption(group.id);
          setSelectionId(group.id);
          setOptionLabel(group.groupName);
          setShowCollaborators(group.collaborators);
          setCreatorId(group.creatorId);
          setBtnDisabled(false);
          console.log(group);
        }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const chooseGroupHandler = (item: any, e: any) => {
    e.stopPropagation();
    setOpenList(false);
    setChooseOption(item.id);
    setSelectionId(item.id);
    setOptionLabel(item.groupName);
    setCollaboratorsList(item.collaborators);
    setShowCollaborators(item.collaborators);
    setCreatorId(item.creatorId);
    console.log(collaboratorsList);

    if (item.id === "create") {
      setsListSettings("");
      setBtnDisabled(true);
      setOptionLabel(item.groupName);
      return;
    }

    if (item.id === "chooseGroup") {
      setsListSettings("hide-modal");
      setBtnDisabled(true);
      return;
    }

    if (item.id !== "create" && item.id !== "chooseGroup")
      setBtnDisabled(false);
    setsListSettings("hide-modal");
    console.log("selections", selections);
    return;
  };

  const editGroup = (id: any) => {
    try {
      (async () => {
        const group = await RestClient.getSelectionGroup(id);
        console.log("edit group", group);
        if (group) {
          setNewGroupName(group.groupName);
          setCollaboratorsList(group.collaborators);
          setShowCollaborators(group.collaborators);
          setCreatorId(group.creatorId);
          setEditMode(true);
          setsListSettings("");
          setBtnSaveDisabled(false);
          setNewGroupId(group.id);
        }
      })();
    } catch (error) {
      console.log(error);
    }
  };

  const hideSettingsHandler = () => {
    setOpenList(false);
    if (editMode) {
      setsListSettings("hide-modal");
      setIsCreate(false);
      setEditMode(false);
      setBtnSaveDisabled(true);
      //setBtnDisabled(true);
      setNewGroupName("");
    } else {
      setsListSettings("hide-modal");
      setChooseOption("chooseGroup");
      setOptionLabel("Вибрати групу");
      setBtnDisabled(true);
      setNewGroupName("");
      setBtnSaveDisabled(true);
      setIsCreate(false);
      setEditMode(false);
    }

    if (emptyRef && emptyRef.current)
      (emptyRef.current as any).emptyCollaboratorsArray();
  };

  const displayChooseSongsModal = () => {
    setChooseSongsModal(true);
    setIsCreate(true);
  };
  const hideChooseSongsModal = () => {
    setChooseSongsModal(false);
    setIsCreate(false);
  };

  const inactiveBtn = {
    background: "#999",
    transform: "scale(1)"
  };
  const inactiveOnhover = {
    boxSshadow: "none",
    transform: "none"
  };

  useEffect(
    function () {
      (async () => {
        if (selectionId === null || selectionId === "create") {
          return;
        }
        try {
          const list: any = await RestClient.getSelections(selectionId);
          setSelections(list || []);
          console.log("list", list);
          setIsSelectionSent(false);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [selectionId, isSelectionSent]
  );

  useEffect(
    function () {
      try {
        if (isNewGroupSent) {
          (async () => {
            const groupsList: any = await RestClient.getSelectionGroups();
            setGroupsList(groupsList || []);
            setIsNewGroupSent(false);
            return groupsList;
          })();
        }
      } catch (error) {
        console.log(error);
      }
    },
    [isNewGroupSent]
  );

  const sure = () => {
    setYesDelete(true);
  };
  const cansel = () => {
    setYesDelete(false);
  };

  return (
    <div className="slist">
      <div className="group-big-box">
        <div className="group-box">
          <p className="slist-setting-btn">Група: </p>
          <div onClick={(e) => togleOpen(e)} className="group-box-input">
            <input
              readOnly
              //onChange={chooseGroupHandler}
              className="slist-choose-group"
              value={optionLabel}
            />
            {openList ? (
              <IoChevronUpOutline className="group-box-icon" />
            ) : (
              <IoChevronDownOutline className="group-box-icon" />
            )}
          </div>
          {openList ? (
            <div className="group-box-list">
              <p
                className="group-box-list-first"
                onClick={(e) =>
                  chooseGroupHandler(
                    {
                      id: "create",
                      groupName: "Створити групу",
                      collaborators: [],
                      creatorId: null
                    },
                    e
                  )
                }
              >
                Створити групу
              </p>
              {groupsList.map((item) => (
                <p onClick={(e) => chooseGroupHandler(item, e)} key={item.id}>
                  {item.groupName}
                </p>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        {chooseOption === "create" ||
        chooseOption === "chooseGroup" ||
        Number(userId) !== Number(creatorId) ? null : (
          <div className="group-btns">
            <button
              className=" group-btns-btn"
              onClick={() => editGroup(chooseOption)}
            >
              <IoPencil />
            </button>
            <button className=" group-btns-btn" onClick={sure}>
              <IoTrashOutline />
            </button>
          </div>
        )}
      </div>
      {chooseOption !== "create" && chooseOption !== "chooseGroup" ? (
        <div className="collaborators">
          {" "}
          <p>Учасники:</p>
          {showCollaborators.map((item) => (
            <p key={item.id}>
              {item.firstName} {item.lastName};
            </p>
          ))}
        </div>
      ) : (
        ""
      )}
      {/*  create || edit selection gorup */}
      <div className={sListSettings || "create-selection-modal"}>
        <div className="settings-box">
          <p className="attributes-users">Введіть назву групи</p>
          <input
            type="text"
            className="create-group-title"
            value={newGroupName}
            onChange={newGroupNameHandler}
          />
          <p className="attributes-users">Виберіть учасників</p>
          <div>
            <SelectUsers
              passingCollaboratorsArray={addCollaborators}
              ref={emptyRef}
              collaboratorsList={collaboratorsList}
              editMode={editMode}
            />
          </div>

          <div className="choose-btns">
            <button
              className={`${
                btnSaveDisabled ? "btn-mcolor-inactive" : "btn-mcolor"
              }`}
              disabled={btnSaveDisabled}
              onClick={savingNewGroup}
            >
              Зберегти
            </button>
            <button className="btn-plain" onClick={hideSettingsHandler}>
              Скасувати
            </button>
          </div>
        </div>
      </div>
      <button
        className={`${
          btnDisabled
            ? "btn-mcolor-inactive slists-btn-create mrg-top"
            : "btn-mcolor slists-btn-create"
        }`}
        disabled={btnDisabled}
        onClick={displayChooseSongsModal}
      >
        Створити підбірку
      </button>

      {/* crete and adit modal for song selections */}
      {chooseSongsModal ? (
        <div className="create-selection-modal">
          <ChooseSongs
            onSavingSongList={saveNewListHandler}
            hideChooseSongsModal={hideChooseSongsModal}
            selectionId={selectionId}
            editedSelection={editedSelection}
            ref={refEdit}
          />
        </div>
      ) : (
        ""
      )}

      <div className="show-songs-margin">
        {selections.map((item: any) => (
          <ShowSongs
            id={item.id}
            name={item.name}
            date={item.date}
            songs={item.songs}
            key={item.id}
            onDelete={deleteSelection}
            editSelection={editSelection}
          />
        ))}
        {/* <SongFullScreen selectedSongs={selections[0]} /> */}
      </div>

      {yesDelete ? (
        <div className="delete">
          <div className="delete-box">
            <p className="delete-box-message">
              Bидалити групу{" "}
              <span className="group-to-delete"> {optionLabel}</span> ?
            </p>
            <div className="delete-box-btns">
              <button
                className="btn-mcolor delete-box-btn "
                onClick={() => deleteGroup(chooseOption)}
              >
                Так
              </button>{" "}
              <button className="btn-plain delete-box-btn " onClick={cansel}>
                Ні
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SLists;
