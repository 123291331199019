import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import "./ChooseSongs.scss";
import {
  IoCloseCircle,
  IoCloseCircleOutline,
  IoPencil,
  IoArrowDownOutline,
  IoArrowUpOutline,
  IoEnter
} from "react-icons/io5";
import SelectSongBook from "../UI/SelectSongBook";
import debounce from "lodash/debounce";
import RestClient from "../../../@core/restClient";
import { Song } from "../../../@core/models";
import moment from "moment";

const ChooseSongs = (props: any, refEdit: any) => {
  const [songList, setSongList]: any = useState([]);
  const [selectVisible, setSelectVisible]: any = useState("");
  const [inputValue, setInputValue]: any = useState("");
  const [bookId, setBookId]: any = useState(null);
  const [songId, setSongId]: any = useState(null);
  const [songTempo, setSongTempo]: any = useState(null);
  const [isValidTitle, setIsValidTitle] = useState(false);
  const [isValidDate, setIsValidDate] = useState(false);
  const [isValidSong, setIsValidSong] = useState(false);
  const [saveOk, setSaveOk] = useState(false);

  const deferredSetQueryHandler = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    }, 500),
    []
  );

  useEffect(() => {
    if (!isValidTitle || !isValidDate || !isValidSong) {
      setSaveOk(false);
    } else {
      setSaveOk(true);
    }
  }, [isValidTitle, isValidDate, isValidSong]);

  const setVisable = () => {
    setSelectVisible("visable");
  };

  useEffect(
    function () {
      (async () => {
        if (bookId === null) {
          return;
        }
        try {
          const list = await RestClient.getSongList(bookId);
          console.log("getSongList response", list);
          setSongList(list || []);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [bookId]
  );

  const filteredSongList = useMemo(() => {
    console.log(inputValue);
    if (inputValue) {
      return songList.filter((song: any) => song.title.includes(inputValue));
    } else {
      return songList;
    }
  }, [inputValue, songList]);

  const getBookId = (bookId: any) => {
    setBookId(bookId);
    console.log("lalala", bookId);
  };

  const selectSong = (song: Song) => {
    ref.current.value = song.title;
    setInputValue(song.title);
    setSongId(song.id);
    setSongTempo(song.tempo);
    //setBookId(song.id);
    setSelectVisible("");

    //setSongbooks([]);
    // setIsOpen(false); // todo: need to be discussed
  };

  const [enteredTitle, setEnteredTitle] = useState("");
  const titleChangeHandler = (event: any) => {
    setEnteredTitle(event.target.value);
    //setIsValidTitle(true);
  };

  const [enteredDate, setEnteredDate] = useState("");
  const dateChangeHandler = (event: any) => {
    setEnteredDate(event.target.value);
    //setIsValidDate(true);
    console.log(event.target.value);
  };

  const ref: any = useRef("");

  const [enteredSongs, setEnteredSongs]: any = useState([]);

  const songChangeHandler = () => {
    let sortId = 0;
    let sort = 0;
    enteredSongs.forEach((item: any) => {
      if (item.id > sortId) sortId = item.id;
      if (item.sort > sort) sort = item.sort;
    });
    const song = {
      sortId: ++sortId,
      id: songId,
      songBookId: bookId,
      title: ref.current.value,
      songTempo: songTempo,
      sort: sort + 10
    };
    console.log(song);
    setEnteredSongs([...enteredSongs, song]);
    ref.current.value = "";
    setInputValue("");

    if (enteredSongs.length === 0) {
      setIsValidSong(false);
    } else {
      setIsValidSong(true);
    }
    console.log(enteredSongs);
  };

  useEffect(() => {
    if (enteredSongs.length === 0) {
      setIsValidSong(false);
    } else {
      setIsValidSong(true);
    }
    if (enteredDate !== "") {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
    if (enteredTitle !== "") {
      setIsValidTitle(true);
    } else {
      setIsValidTitle(false);
    }
  }, [enteredSongs, enteredDate, enteredTitle]);

  const deleteSong = (song: any) => {
    const newEnteredSongs = enteredSongs.filter((item: any) => item !== song);
    setEnteredSongs(newEnteredSongs);
  };

  const moveSong = (song: any, direction: any) => {
    enteredSongs.forEach((item: any) => {
      if (item.id === song.id) {
        if (direction === "up") item.sort = item.sort - 15;
        if (direction === "down") item.sort = item.sort + 15;
      }
    });
    enteredSongs.sort((a: any, b: any) => {
      return a.sort - b.sort;
    });
    console.log(enteredSongs);
    let counter = 0;
    enteredSongs.forEach((item: any) => {
      item.sort = counter + 10;
      counter = counter + 10;
    });
    console.log(enteredSongs);
    setEnteredSongs([...enteredSongs]);
  };

  const submitHandler = () => {
    const chosenSongs = {
      id: props.editedSelection.id,
      groupId: props.selectionId,
      name: enteredTitle,
      date: moment(enteredDate).format("YYYY-MM-DD"),
      songs: enteredSongs.map((el: any) => el.id),
      order: enteredSongs.map((el: any) => el.id)
    };
    console.log("chosen songs", chosenSongs);
    console.log("entered songs", enteredSongs);

    props.onSavingSongList(chosenSongs);
    setEnteredTitle("");
    setEnteredDate("");
    setEnteredSongs([]);

    props.hideChooseSongsModal();
  };

  useImperativeHandle(refEdit, () => ({
    edit: () => {
      setEnteredTitle(props.editedSelection.name);
      //setEnteredDate(props.editedSelection.date);
      const d = moment(props.editedSelection.date).format("YYYY-MM-DD");
      console.log(d);
      setEnteredDate(d);
      setEnteredSongs(props.editedSelection.songs);

      console.log("props songs", props.editedSelection.songs);
      console.log("entered songs new", enteredSongs);
    }
  }));

  const hideModal = () => {
    props.hideChooseSongsModal();
    setEnteredTitle("");
    setEnteredDate("");
    setEnteredSongs([]);
    setSelectVisible("");
    console.log("here", selectVisible);
    ref.current.value = "";
  };

  return (
    <div>
      <div className="choose">
        <div className="choose-input">
          <label>Виберіть збірник</label>
          <SelectSongBook
            passBookId={getBookId}
            selectVisible={selectVisible}
          />
          <label htmlFor="title">Введіть назву або ведучого</label>
          <input
            type="text"
            name="title"
            className="choose-title"
            value={enteredTitle}
            onChange={titleChangeHandler}
            style={{
              backgroundColor: !isValidTitle ? "rgb(255, 247, 237)" : "#fff"
            }}
          />

          <label htmlFor="date">Виберіть дату</label>
          <input
            type="date"
            name="date"
            className="choose-title"
            value={enteredDate.substring(0, 10)}
            onChange={dateChangeHandler}
            style={{
              backgroundColor: !isValidDate ? "rgb(255, 247, 237)" : "#fff"
            }}
          />

          <label htmlFor="song">Виберіть пісню</label>
          <div className="choose-song">
            <input
              type="text"
              name="song"
              className="choose-title"
              ref={ref}
              style={{
                backgroundColor: !isValidSong ? "rgb(255, 247, 237)" : "#fff"
              }}
              onChange={deferredSetQueryHandler}
              onClick={setVisable}
            />
            <button className="choose-btn" onClick={songChangeHandler}>
              <IoEnter className="choose-btn-icon" />
            </button>
            <div className={selectVisible || "select-box"}>
              {filteredSongList.map((song: any) => (
                <div
                  key={song.id}
                  className="select-box-item"
                  onClick={() => selectSong(song)}
                >
                  {song.title}
                </div>
              ))}
              <IoCloseCircleOutline
                className="select-close"
                onClick={() => setSelectVisible("")}
              />
            </div>
          </div>
        </div>
        <div>
          <ol className="chosen-songs">
            {enteredSongs.map((el: any) => (
              <li key={el.id}>
                <div className="chosen-songs-item">
                  <p>{el.title}</p>

                  <div className="chosen-songs-icons">
                    <IoArrowUpOutline
                      className="icon"
                      onClick={() => moveSong(el, "up")}
                    />
                    <IoArrowDownOutline
                      className="icon"
                      onClick={() => moveSong(el, "down")}
                    />
                    <IoCloseCircle
                      className="icon"
                      onClick={() => deleteSong(el)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </div>
        <div className="choose-btns">
          <button
            className={saveOk ? "btn-mcolor" : "btn-mcolor-inactive"}
            disabled={saveOk ? false : true}
            onClick={submitHandler}
          >
            Зберегти
          </button>
          <button className="btn-plain" onClick={hideModal}>
            Скасувати
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(ChooseSongs);
