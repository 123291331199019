import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import "./SelectUsers.scss";
import {
  IoEnter,
  IoSearchOutline,
  IoCloseCircleOutline
} from "react-icons/io5";
import RestClient from "../../../@core/restClient";
import { listenerCancelled } from "@reduxjs/toolkit/dist/listenerMiddleware/exceptions";
import debounce from "lodash/debounce";
import { Users } from "../../../@core/models";

const SelectUsers = (
  { passingCollaboratorsArray, editMode, collaboratorsList }: any,
  ref: any
) => {
  const [userList, setUserList]: any = useState([]);
  const [selectVisible, setSelectVisible]: any = useState("");
  const [inputValue, setInputValue]: any = useState("");
  const [userId, setUserId]: any = useState(null);
  const [userFirstName, setUserFirstName]: any = useState("");
  const [userLastName, setUserLastName]: any = useState("");
  const [selectedUsers, setSelectedUsers]: any = useState<any>([]);

  const emailRef = useRef<any>();

  useEffect(
    function () {
      if (editMode === true) setSelectedUsers(collaboratorsList);
      console.log(collaboratorsList);
    },
    [editMode]
  );

  const deferredSetQueryHandler = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target?.value);
    }, 500),
    []
  );

  useImperativeHandle(ref, () => ({
    emptyCollaboratorsArray: () => {
      setSelectedUsers([]);
      emailRef.current.value = "";
      console.log("field emptied");
    }
  }));

  useEffect(
    function () {
      (async () => {
        if (
          emailRef.current !== document.activeElement ||
          emailRef.current?.value !== inputValue
        ) {
          return;
        }
        try {
          const list = await RestClient.getUsers(inputValue);
          console.log("getUsersList response", list);
          setUserList(list || []);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    [inputValue, selectVisible]
  );

  const selectUser = (users: Users, e: any) => {
    e.stopPropagation();
    emailRef.current.value = `${users.firstName} ${users.lastName}`;
    setInputValue(users.email);
    setUserId(users.id);
    setSelectVisible("");
    setUserFirstName(users.firstName);
    setUserLastName(users.lastName);

    //setSongbooks([]);
    // setIsOpen(false); // todo: need to be discussed
  };

  const addUser = () => {
    const selectedUser = {
      id: userId,
      email: inputValue,
      firstName: userFirstName,
      lastName: userLastName
    };
    console.log(selectedUser);
    setSelectedUsers([...selectedUsers, selectedUser]);
    emailRef.current.value = "";
  };

  const clear = () => {
    setSelectVisible("visable");
    setInputValue("");
    emailRef.current.value = "";
  };

  const deleteUser = (user: any) => {
    const newSelectedUsers = selectedUsers.filter((item: any) => item !== user);
    setSelectedUsers(newSelectedUsers);
  };

  const doNothing = () => {
    console.log("do nothing");
  };

  useEffect(
    function () {
      try {
        passingCollaboratorsArray(selectedUsers);
      } catch (error) {
        console.log(error);
      }
    },
    [selectedUsers]
  );

  return (
    <div className="select">
      <input
        className="select-input"
        onClick={clear}
        ref={emailRef}
        onChange={deferredSetQueryHandler}
      />
      <div className={selectVisible || "select-box"}>
        {userList.map((user: any) => (
          <div
            key={user.id}
            className="select-box-item"
            onClick={(e) => selectUser(user, e)}
          >
            {user.firstName} {user.lastName}{" "}
            <span className="user-email">{user.email}</span>{" "}
          </div>
        ))}
        <IoCloseCircleOutline
          className="select-close"
          onClick={() => setSelectVisible("")}
        />
      </div>
      <div
        className="select-icon"
        onClick={emailRef?.current?.value === "" ? doNothing : addUser}
      >
        <IoEnter />
      </div>
      <IoSearchOutline className="select-search" />
      <div className="added">
        {selectedUsers.map((person: any) => (
          <div className="added-sb-user" key={person.id}>
            {person.firstName} {person.lastName}
            <IoCloseCircleOutline
              className="added-sb-user-icon"
              onClick={() => deleteUser(person)}
            />
          </div>
        ))}
      </div>
      {/* <button onClick={emptyCollaboratorsArray}>clear</button> */}
    </div>
  );
};

export default forwardRef(SelectUsers);
