import React, { useEffect, useState } from "react";
import "./ShowSongs.scss";
import {
  IoCloseCircle,
  IoPencil,
  IoDesktopOutline,
  IoTrashOutline
} from "react-icons/io5";
import moment from "moment";
import RestClient from "../../../@core/restClient";
import SongFullScreen from "../UI/SongFullScreen";
import { useDeprecatedAnimatedState, useScroll } from "framer-motion";

const ShowSongs = (props: any) => {
  const date = moment(props.date).format("DD.MM.YYYY");

  const [selectedSongs, setSelectedSongs] = useState<any[]>([]);
  const [screenMode, setScreenMode] = useState(false);

  const closeFullScreen = () => {
    setScreenMode(false);
  };

  return (
    <div>
      <div className="show-slist">
        <div className="show-slist-title">
          <p className="show-slist-title-name">{props.name}</p>
          <p className="show-slist-title-date">{date}</p>
          <div className="show-slist-icons">
            <IoDesktopOutline
              className="show-slist-icons-item"
              onClick={() => setScreenMode(true)}
            />
            <IoPencil
              className="show-slist-icons-item"
              onClick={() =>
                props.editSelection(
                  props.id,
                  props.name,
                  props.date,
                  props.songs
                )
              }
            />
            <IoTrashOutline
              className="show-slist-icons-item"
              onClick={() => props.onDelete(props.id)}
            />
          </div>
        </div>
        <ol className="show-slist-list">
          {props.songs.map((el: any) => (
            <li className="show-slist-item" key={el.id}>
              {el.title}
              {/* <span>t-{el.tempo}</span>{" "} */}
            </li>
          ))}
        </ol>
      </div>
      {screenMode ? (
        <div>
          <SongFullScreen
            selectedSongs={props.songs}
            closeFullScreen={closeFullScreen}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ShowSongs;
